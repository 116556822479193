import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type TypeTopUser = {
  balance: number;
  position: number;
  username: string;
};

type TypeRatingResponse = {
  top_users: TypeTopUser[];
  user_position: number;
};

interface IRating {
  response: TypeRatingResponse;
}

const initialState: IRating = {
  response: null,
};

const ratingSlice = createSlice({
  name: 'rating',
  initialState,
  reducers: {
    setRatingResponse: (state, { payload }: PayloadAction<TypeRatingResponse>) => {
      state.response = payload;
    },
  },
});

export const { setRatingResponse } = ratingSlice.actions;

export default ratingSlice.reducer;
