import React from 'react';
import toast from 'react-hot-toast';

import styles from '../Friends.module.scss';

import type { TypeStatus } from '@interfaces/status.types';

import { toastDuration, toastID, toastText } from '@configs/constants/toast';

import customTimeout from '@utils/customTimeout';

import useSound from '@hooks/useSound';

import { useAppSelector } from '@store/index';

import { Button } from '@components/UI';

const ShareButtons: React.FC = () => {
  const settingsResponse = useAppSelector((state) => state.settings.response);
  const friendsResponse = useAppSelector((state) => state.friends.response);

  const [copyLinkStatus, setCopyLinkStatus] = React.useState<TypeStatus>('loaded');

  const { defaultSound } = useSound();

  const link = friendsResponse?.ref_url || '';

  const shareText: string = settingsResponse?.share_text || '';
  const encodedShareText = encodeURIComponent(shareText);

  const openTelegramShareWindow = React.useCallback(() => {
    const telegramUrl = `https://t.me/share/url?url=${link}&text=${encodedShareText}`;
    window.open(telegramUrl, '_blank');
  }, [link, encodedShareText]);

  const onClickShareTG = React.useCallback(() => {
    try {
      openTelegramShareWindow();
    } catch (err) {
      console.log(err);
      toast.error(toastText.error, { id: toastID, duration: toastDuration, className: 'toast-error' });
    }
  }, [openTelegramShareWindow]);

  const onClickCopyLink = React.useCallback(async () => {
    defaultSound();
    setCopyLinkStatus('loading');
    toast.loading(toastText.loading, { id: toastID, className: 'toast-loading' });

    await customTimeout(300);

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(`${link}\n\n${shareText}`)
        .then(() => {
          setCopyLinkStatus('loaded');
          toast.success('Copied!', { id: toastID, duration: toastDuration, className: 'toast-success' });
        })
        .catch((err) => {
          console.log(err);
          setCopyLinkStatus('loaded');
          openTelegramShareWindow();
        })
        .finally(() => {
          setCopyLinkStatus('loaded');
        });
    } else {
      setCopyLinkStatus('loaded');
      openTelegramShareWindow();
    }
  }, [openTelegramShareWindow, link, shareText]);

  return (
    <div className={styles.FriendsButtons}>
      <Button onClick={onClickShareTG}>
        Invite for
        <i className="button-ticket">
          <img src={require('@assets/img/currencies/ticket.webp')} alt="Ticket" />
        </i>
      </Button>

      <button className={styles.FriendsCopyReferralLink} onClick={onClickCopyLink} disabled={copyLinkStatus !== 'loaded'} type="button">
        <img src={require('@assets/img/icons/copy.webp')} alt="Copy" />
      </button>
    </div>
  );
};

export default ShareButtons;
