import { createSlice, createAsyncThunk, type PayloadAction } from '@reduxjs/toolkit';

import type IUserModel from '@interfaces/models/UserModel';
import type { TypeStatus } from '@interfaces/status.types';

import { preloaderTime, setActiveModal, setSettingsResponse } from './settings';

import customTimeout from '@utils/customTimeout';

import { API, initDataString, setAuthToken } from '@services/API';

import { setRatingResponse } from './rating';
import { setEarnResponse } from './earn';
import { setFriendsResponse } from './friends';
import { setWheelPrizes } from './wheel';
import { setShopResponse } from './shop';

// Отправляем запрос на бек с получением пользователя
export const fetchGetUser: any = createAsyncThunk('fetchGetUser', async ({}, { dispatch }) => {
  try {
    const webApp = window.Telegram.WebApp;
    const initData = webApp.initData;

    const initDataValue = window.location.hostname === 'localhost' ? initDataString : initData;
    const start_param = webApp.initDataUnsafe.start_param;

    const { data } = await API.post('/login', {
      init_data: initDataValue,
      start_param: start_param || '',
    });

    if (!data) return;

    const { jwtData, prizes, rating, user, referrals, settings, tasks, shop_items } = data; // Достаем данные пользователя и JWT токен

    setAuthToken(jwtData.token); // Сохраняем JWT токен

    dispatch(setSettingsResponse(settings));
    dispatch(setRatingResponse(rating));
    dispatch(setEarnResponse(tasks));
    dispatch(setFriendsResponse(referrals));
    dispatch(setWheelPrizes(prizes));
    dispatch(setShopResponse(shop_items));

    if (!user.is_free_tickets_claimed) {
      dispatch(setActiveModal(['daily-tickets']));
    }

    return user; // Возвращаем данные пользователя
  } catch (err) {
    console.log(err);

    await customTimeout(preloaderTime);

    return null;
  }
});

interface IAuth {
  user: IUserModel;

  userStatus: TypeStatus;
}

const initialState: IAuth = {
  user: null,

  userStatus: 'loading',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserStatus: (state, { payload }: PayloadAction<IAuth['userStatus']>) => {
      state.userStatus = payload;
    },

    setUpgradeUser: (state, { payload }: PayloadAction<Partial<IUserModel>>) => {
      if (payload) {
        state.user = {
          ...state.user,
          ...payload,
        };
      }
    },

    setResetDailyReward: (state) => {
      state.user.is_free_tickets_claimed = false;
    },

    setDecreaseTicket: (state) => {
      state.user.balances.balance_tickets = state.user.balances.balance_tickets - 1;
    },
  },
  extraReducers: {
    // Получаем пользователя
    [fetchGetUser.fulfilled]: (state, { payload }: PayloadAction<IUserModel | string>) => {
      if (payload === undefined) {
        state.userStatus = 'error';

        return;
      }

      if (payload) {
        state.user = {
          ...(payload as IUserModel),
        };
      } else {
        state.userStatus = 'error';
      }
    },
  },
});

export const { setUserStatus, setUpgradeUser, setResetDailyReward, setDecreaseTicket } = authSlice.actions;

export default authSlice.reducer;
