import React from 'react';

import { type TypeFriend } from '@store/slices/friends';

import styles from './FriendItem.module.scss';
import classNames from 'classnames';

type Props = TypeFriend;

const FriendItem: React.FC<Props> = ({ balance, position, username, is_premium }) => {
  return (
    <div className={styles.FriendItem}>
      <span className={classNames(styles.FriendItemName, is_premium && 'text-[#FFDC4A]')}>{username}</span>

      <div className={styles.FriendItemCurrency}>
        <span className={styles.FriendItemCurrencyCount}>{balance}</span>

        <i className={styles.FriendItemCurrencyLogo}>
          <img src={require('@assets/img/currencies/coin.webp')} alt="Coin" />
        </i>
      </div>
    </div>
  );
};

export default React.memo(FriendItem);
