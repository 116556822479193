import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './NoTicketsModal.module.scss';

import paths from '@configs/Router/paths';

import useModal from '@hooks/useModal';

import ModalTemplate from '@components/layouts/ModalTemplate';
import { Button, ModalTitle } from '@components/UI';

function NoTicketsModal() {
  const navigate = useNavigate();

  const { setCloseModal } = useModal();

  const onGetMore = React.useCallback(() => {
    try {
      setCloseModal('no-tickets');
      navigate(paths.Earn);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <ModalTemplate name="no-tickets" type="wood-center">
      <div className={styles.NoTicketsModalRow}>
        <i className={styles.NoTicketsModalTicketLogo}>
          <img src={require('@assets/img/currencies/ticket.webp')} alt="Ticket" />

          <i className={styles.NoTicketsModalTicketEmoji}>
            <img src={require('@assets/img/emoji/lose.webp')} alt="No tickets" />
          </i>
        </i>
      </div>

      <ModalTitle className={styles.NoTicketsModalTitle}>
        There are <br /> no tickets
      </ModalTitle>

      <div className={styles.NoTicketsModalText}>
        to earn more tickets <br /> complete various tasks
      </div>

      <Button className="mx-auto" onClick={onGetMore} size="168px">
        get more
      </Button>
    </ModalTemplate>
  );
}

export default NoTicketsModal;
