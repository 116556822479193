import React from 'react';

type TypeUseIsChildAtBottomReturn = boolean;

function useIsChildAtBottom(ref: React.RefObject<HTMLElement>): TypeUseIsChildAtBottomReturn {
  const [isAtBottom, setIsAtBottom] = React.useState<boolean>(false);

  React.useEffect(() => {
    const checkIsAtBottom = () => {
      if (ref.current) {
        const child = ref.current;
        const parent = child.offsetParent as HTMLElement | null;

        if (parent) {
          const childBottom = child.offsetTop + child.offsetHeight;
          const parentHeight = parent.clientHeight;

          // Сравнение с небольшой погрешностью
          setIsAtBottom(Math.abs(childBottom - parentHeight) <= 1);
        }
      }
    };

    // Слушаем только изменение размера
    window.addEventListener('resize', checkIsAtBottom);

    // Проверка сразу при монтировании
    checkIsAtBottom();

    return () => {
      window.removeEventListener('resize', checkIsAtBottom);
    };
  }, [ref]);

  return isAtBottom;
}

export default useIsChildAtBottom;
