// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tWHKdBrvjzeweE_3L7um {
  width: 46px;
  height: 46px;
  display: block;
  cursor: pointer;
}
@media (max-width: 430px) {
  .tWHKdBrvjzeweE_3L7um {
    width: calc(38px - -8 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .tWHKdBrvjzeweE_3L7um {
    width: 38px;
  }
}
@media (max-width: 430px) {
  .tWHKdBrvjzeweE_3L7um {
    height: calc(38px - -8 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .tWHKdBrvjzeweE_3L7um {
    height: 38px;
  }
}
.tWHKdBrvjzeweE_3L7um:disabled {
  pointer-events: none;
}
.tWHKdBrvjzeweE_3L7um:active {
  transform: scaleY(0.96) scaleX(1.18);
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Close/Close.module.scss","webpack://./src/styles/mixins.scss"],"names":[],"mappings":"AACA;ECEE,WAAA;EAAA,YAAA;EDCA,cAAA;EACA,eAAA;AAAF;ACAE;EDJF;ICQI,8CAAA;EDAF;AACF;ACEE;EDXF;ICYI,WAAA;EDCF;AACF;ACVE;EDJF;ICQI,+CAAA;EDUF;AACF;ACRE;EDXF;ICYI,YAAA;EDWF;AACF;AAlBE;EACE,oBAAA;AAoBJ;AAjBE;EACE,oCAAA;AAmBJ","sourcesContent":["@use '@styles/mixins.scss' as *;\n.Close {\r\n  @include fluid(width, 46px, 38px);\r\n  @include fluid(height, 46px, 38px);\r\n  display: block;\r\n  cursor: pointer;\r\n\r\n  &:disabled {\r\n    pointer-events: none;\r\n  }\r\n\r\n  &:active {\r\n    transform: scaleY(0.96) scaleX(1.18);\r\n  }\r\n}\r\n","@use 'sass:math';\r\n\r\n@mixin fluid($property, $max, $min, $min-screen: 320px, $max-screen: 430px) {\r\n  #{$property}: #{$max};\r\n\r\n  @media (max-width: #{$max-screen}) {\r\n    $value-range: math.div($min - $max, 1px);\r\n    $screen-range: math.div($max-screen - $min-screen, 1px);\r\n\r\n    #{$property}: calc(#{$min} - #{$value-range} * (100vw - #{$min-screen}) / #{$screen-range});\r\n  }\r\n\r\n  @media (max-width: #{$min-screen}) {\r\n    #{$property}: #{$min};\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Close": `tWHKdBrvjzeweE_3L7um`
};
export default ___CSS_LOADER_EXPORT___;
