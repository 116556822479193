import React from 'react';

import { WebSocketService } from '@services/webSocket';

import { useAppDispatch } from './store';
import { fetchGetUser, setUserStatus } from '@store/slices/auth';
import { setMarqueeResponse } from '@store/slices/marquee';

import Router from '@configs/Router';

function App() {
  const dispatch = useAppDispatch();

  const wsServiceRef = React.useRef<WebSocketService | null>(null);

  React.useEffect(() => {
    dispatch(fetchGetUser());
  }, []);

  React.useEffect(() => {
    try {
      const wsService = new WebSocketService();
      wsServiceRef.current = wsService;

      wsService.connect(
        '/ws',
        (data) => {
          dispatch(setMarqueeResponse(data));
        },
        (err) => {
          dispatch(setUserStatus('error'));
        }
      );

      return () => {
        wsService.close();
      };
    } catch (err) {
      console.log(err);
    }
  }, []);

  return <Router />;
}

export default App;
