// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yDf6a8qxDQl7a0mgYwaY {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
}

.iBQTuxWAE7sGkAQqIWEN {
  margin-bottom: -210px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 430px) {
  .iBQTuxWAE7sGkAQqIWEN {
    margin-bottom: calc(-110px - 100 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .iBQTuxWAE7sGkAQqIWEN {
    margin-bottom: -110px;
  }
}

.EfZs3X43N7pJlgp3HlLG {
  margin: 0 auto;
  width: 280px;
}
@media (max-width: 430px) {
  .EfZs3X43N7pJlgp3HlLG {
    width: calc(225px - -55 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .EfZs3X43N7pJlgp3HlLG {
    width: 225px;
  }
}
.EfZs3X43N7pJlgp3HlLG img {
  max-width: 100%;
}
.EfZs3X43N7pJlgp3HlLG:not(:last-child) {
  margin-bottom: 24px;
}
@media (max-width: 430px) {
  .EfZs3X43N7pJlgp3HlLG:not(:last-child) {
    margin-bottom: calc(8px - -16 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .EfZs3X43N7pJlgp3HlLG:not(:last-child) {
    margin-bottom: 8px;
  }
}

.ZEMUtQTTCzdwXVDzMVhS {
  font-size: 32px;
}
@media (max-width: 430px) {
  .ZEMUtQTTCzdwXVDzMVhS {
    font-size: calc(22px - -10 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .ZEMUtQTTCzdwXVDzMVhS {
    font-size: 22px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/Error/Error.module.scss","webpack://./src/styles/mixins.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;AAAF;;AAGA;ECNE,qBAAA;EDQA,aAAA;EACA,sBAAA;EACA,mBAAA;AAAF;ACRE;EDIF;ICAI,yDAAA;EDQF;AACF;ACNE;EDHF;ICII,qBAAA;EDSF;AACF;;AAPA;EACE,cAAA;ECdA,YAAA;ADyBF;ACvBE;EDWF;ICPI,gDAAA;EDuBF;AACF;ACrBE;EDIF;ICHI,YAAA;EDwBF;AACF;AAlBE;EACE,eAAA;AAoBJ;AAjBE;ECrBA,mBAAA;ADyCF;ACvCE;EDmBA;ICfE,sDAAA;EDuCF;AACF;ACrCE;EDYA;ICXE,kBAAA;EDwCF;AACF;;AAzBA;EC1BE,eAAA;ADuDF;ACrDE;EDwBF;ICpBI,mDAAA;EDqDF;AACF;ACnDE;EDiBF;IChBI,eAAA;EDsDF;AACF","sourcesContent":["@use '@styles/mixins.scss' as *;\n.ErrorContainer {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n  flex: 1 1 auto;\r\n}\r\n\r\n.ErrorBox {\r\n  @include fluid(margin-bottom, -210px, -110px);\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n}\r\n\r\n.ErrorImage {\r\n  margin: 0 auto;\r\n  @include fluid(width, 280px, 225px);\r\n\r\n  img {\r\n    max-width: 100%;\r\n  }\r\n\r\n  &:not(:last-child) {\r\n    @include fluid(margin-bottom, 24px, 8px);\r\n  }\r\n}\r\n\r\n.ErrorText {\r\n  @include fluid(font-size, 32px, 22px);\r\n}\r\n","@use 'sass:math';\r\n\r\n@mixin fluid($property, $max, $min, $min-screen: 320px, $max-screen: 430px) {\r\n  #{$property}: #{$max};\r\n\r\n  @media (max-width: #{$max-screen}) {\r\n    $value-range: math.div($min - $max, 1px);\r\n    $screen-range: math.div($max-screen - $min-screen, 1px);\r\n\r\n    #{$property}: calc(#{$min} - #{$value-range} * (100vw - #{$min-screen}) / #{$screen-range});\r\n  }\r\n\r\n  @media (max-width: #{$min-screen}) {\r\n    #{$property}: #{$min};\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ErrorContainer": `yDf6a8qxDQl7a0mgYwaY`,
	"ErrorBox": `iBQTuxWAE7sGkAQqIWEN`,
	"ErrorImage": `EfZs3X43N7pJlgp3HlLG`,
	"ErrorText": `ZEMUtQTTCzdwXVDzMVhS`
};
export default ___CSS_LOADER_EXPORT___;
