function getUTCTimeToNewDay(time: number): string {
  const now = new Date();
  const nextTarget = new Date();
  nextTarget.setUTCHours(time, 0, 0, 0);
  if (now.getUTCHours() >= time) {
    nextTarget.setUTCDate(nextTarget.getUTCDate() + 1);
  }

  const difference = nextTarget.getTime() - now.getTime();

  const hours = Math.floor(difference / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

export default getUTCTimeToNewDay;
