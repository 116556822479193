// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HUB5McH0_ccmMUXdtsRc {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
}
@media (max-width: 430px) {
  .HUB5McH0_ccmMUXdtsRc {
    padding-top: calc(16px - -8 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .HUB5McH0_ccmMUXdtsRc {
    padding-top: 16px;
  }
}

.jSJwkjwupBMzRasArnKb {
  font-size: 26px;
  text-align: center;
}
@media (max-width: 430px) {
  .jSJwkjwupBMzRasArnKb {
    font-size: calc(20px - -6 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .jSJwkjwupBMzRasArnKb {
    font-size: 20px;
  }
}
.jSJwkjwupBMzRasArnKb:not(:last-child) {
  margin-bottom: 16px;
}
@media (max-width: 430px) {
  .jSJwkjwupBMzRasArnKb:not(:last-child) {
    margin-bottom: calc(8px - -8 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .jSJwkjwupBMzRasArnKb:not(:last-child) {
    margin-bottom: 8px;
  }
}

.ZnV4_IAIz_ngoQTTSV8a {
  gap: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 430px) {
  .ZnV4_IAIz_ngoQTTSV8a {
    gap: calc(6px - -4 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .ZnV4_IAIz_ngoQTTSV8a {
    gap: 6px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Earn/Earn.module.scss","webpack://./src/styles/mixins.scss"],"names":[],"mappings":"AACA;ECEE,iBAAA;EDAA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,cAAA;AAAF;ACDE;EDJF;ICQI,oDAAA;EDCF;AACF;ACCE;EDXF;ICYI,iBAAA;EDEF;AACF;;AAPA;ECNE,eAAA;EDQA,kBAAA;AAUF;AChBE;EDIF;ICAI,kDAAA;EDgBF;AACF;ACdE;EDHF;ICII,eAAA;EDiBF;AACF;AAlBE;ECVA,mBAAA;AD+BF;AC7BE;EDQA;ICJE,qDAAA;ED6BF;AACF;AC3BE;EDCA;ICAE,kBAAA;ED8BF;AACF;;AA1BA;ECfE,SAAA;EDiBA,WAAA;EACA,aAAA;EACA,sBAAA;AA6BF;AC9CE;EDaF;ICTI,2CAAA;ED8CF;AACF;AC5CE;EDMF;ICLI,QAAA;ED+CF;AACF","sourcesContent":["@use '@styles/mixins.scss' as *;\n.EarnContent {\r\n  @include fluid(padding-top, 24px, 16px);\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  flex: 1 1 auto;\r\n}\r\n\r\n.EarnTitle {\r\n  @include fluid(font-size, 26px, 20px);\r\n  text-align: center;\r\n\r\n  &:not(:last-child) {\r\n    @include fluid(margin-bottom, 16px, 8px);\r\n  }\r\n}\r\n\r\n.EarnList {\r\n  @include fluid(gap, 10px, 6px);\r\n  width: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n","@use 'sass:math';\r\n\r\n@mixin fluid($property, $max, $min, $min-screen: 320px, $max-screen: 430px) {\r\n  #{$property}: #{$max};\r\n\r\n  @media (max-width: #{$max-screen}) {\r\n    $value-range: math.div($min - $max, 1px);\r\n    $screen-range: math.div($max-screen - $min-screen, 1px);\r\n\r\n    #{$property}: calc(#{$min} - #{$value-range} * (100vw - #{$min-screen}) / #{$screen-range});\r\n  }\r\n\r\n  @media (max-width: #{$min-screen}) {\r\n    #{$property}: #{$min};\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EarnContent": `HUB5McH0_ccmMUXdtsRc`,
	"EarnTitle": `jSJwkjwupBMzRasArnKb`,
	"EarnList": `ZnV4_IAIz_ngoQTTSV8a`
};
export default ___CSS_LOADER_EXPORT___;
