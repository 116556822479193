import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { TypeCurrency } from '@interfaces/currencies.types';

export type TypeWheelPrize = {
  count: number;
  name: TypeCurrency | 'NONE';
};

interface IWheel {
  prizes: TypeWheelPrize[];
}

const initialState: IWheel = {
  prizes: [],
};

const wheelSlice = createSlice({
  name: 'wheel',
  initialState,
  reducers: {
    setWheelPrizes: (state, { payload }: PayloadAction<IWheel['prizes']>) => {
      state.prizes = payload;
    },
  },
});

export const { setWheelPrizes } = wheelSlice.actions;

export default wheelSlice.reducer;
