import Home from './Home';

import Rating from './Rating';

import Earn from './Earn';

import Friends from './Friends';

import Shop from './Shop';

export { Home, Rating, Earn, Friends, Shop };
