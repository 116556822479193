import React from 'react';
import classNames from 'classnames';
import { type UseFormRegisterReturn, type FieldError } from 'react-hook-form';

import styles from './TextField.module.scss';

type Props = {
  className?: string;
  hookForm?: UseFormRegisterReturn;
  label?: React.ReactNode;
  disabled?: boolean;
  type?: HTMLInputElement['type'];
  placeholder?: HTMLInputElement['placeholder'];
  error?: FieldError;

  before?: React.ReactNode;
  after?: React.ReactNode;
};

const TextField: React.FC<Props> = ({ className, hookForm, label, disabled, type = 'text', placeholder = '', error, before, after }) => {
  return (
    <div className={classNames(styles.TextField, className)}>
      {label && <div className={styles.TextFieldLabel}>{label}</div>}

      <div className={styles.TextFieldInner}>
        {before && <div className={styles.TextFieldBefore}>{before}</div>}

        <input className={styles.TextFieldInput} {...hookForm} type={type} disabled={disabled} placeholder={placeholder} />

        {after && <div className={styles.TextFieldAfter}>{after}</div>}
      </div>

      {error && <div className={styles.TextFieldError}>{error?.message}</div>}
    </div>
  );
};

export default React.memo(TextField);
