import React from 'react';
import { Toaster, toast } from 'react-hot-toast';

import { Outlet, useLocation } from 'react-router-dom';

import Preloader from './Preloader';
import Error from './Error';
import Maintenance from './Maintenance';
import Menu from './Menu';
import { DailyTicketsModal, SettingsModal } from '@components/modals';

function MainLayout() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    toast.dismiss();
  }, [pathname]);

  return (
    <div className="wrapper">
      <Preloader />

      <Error />

      <Maintenance />

      <Menu />

      <main className="flex flex-col flex-auto overflow-hidden">
        <Outlet />

        <DailyTicketsModal />

        <SettingsModal />
      </main>

      <Toaster containerStyle={{ position: 'absolute' }} />
    </div>
  );
}

export default MainLayout;
