import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import styles from './EarnItem.module.scss';

import type ITaskModel from '@interfaces/models/TaskModel';

import useSound from '@hooks/useSound';
import useAdsgram from '@hooks/useAdsgram';
import useModal from '@hooks/useModal';

import { BASE_URL } from '@services/API';

import { toastID, toastDuration, toastText } from '@configs/constants/toast';

import { useAppDispatch, useAppSelector } from '@store';
import { setCurrentTaskModalID, setTaskCanComplete } from '@store/slices/earn';

type Props = ITaskModel;

const EarnItem: React.FC<Props> = ({ task_photo, task_name, is_complated, task_reward, friends_to_claim, check_type, can_complate, target_url, _id }) => {
  const dispatch = useAppDispatch();
  const settingsResponse = useAppSelector((state) => state.settings?.response);

  let complete: boolean = can_complate;
  const localComplete = Boolean(JSON.parse(window.localStorage.getItem('ads-task-complete')));

  if (check_type === 'watch_ad' && localComplete) {
    complete = true;
  } else {
    complete = can_complate;
  }

  const navigate = useNavigate();

  const webApp = window.Telegram.WebApp;

  const isHttp = React.useMemo(() => target_url?.includes('https:'), [target_url]);
  const isTgLink = React.useMemo(() => target_url?.includes('https://t.me'), [target_url]);

  const { defaultSound } = useSound();
  const { setOpenModal } = useModal();

  const onOpenLink = async () => {
    try {
      if (complete) return;

      if (target_url === '') return;

      if (isHttp) {
        if (isTgLink) {
          webApp.openTelegramLink(target_url);
        } else {
          webApp.openLink(target_url);
        }
      } else {
        navigate(target_url);
      }
    } catch (err) {
      const message = err?.response?.data?.error_message || toastText.error;
      toast.error(message, { id: toastID, duration: toastDuration, className: 'toast-error' });
    }
  };

  const handleAdReward = React.useCallback(() => {
    dispatch(setTaskCanComplete({ _id, can: true }));
  }, [_id]);

  const onShowAdsgram = useAdsgram({
    blockId: String(settingsResponse?.adsgram_block_id),
    onReward: handleAdReward,
  });

  const onShowAdSonar = () => {
    try {
      (window as any).Sonar?.show({
        adUnit: 'rewarded',

        onReward: handleAdReward,
      });
    } catch (err) {
      toast.error(toastText.error, { id: toastID, duration: toastDuration, className: 'toast-error' });
    }
  };

  const onComplete = () => {
    if (!complete && target_url !== '') {
      onOpenLink();

      return;
    }

    dispatch(setCurrentTaskModalID(_id));
    setOpenModal('claim-task');
  };

  const onCompleteADS = () => {
    if (complete) {
      dispatch(setCurrentTaskModalID(_id));
      setOpenModal('claim-task');

      return;
    }

    if (settingsResponse?.ads_type === 'ADSGRAM') {
      onShowAdsgram();
    } else if (settingsResponse?.ads_type === 'ADSONAR') {
      onShowAdSonar();
    }
  };

  const onClick = () => {
    defaultSound();

    switch (check_type) {
      case 'watch_ad':
        onCompleteADS();

        break;
      default:
        onComplete();
        break;
    }
  };

  return (
    <div className={classNames(styles.EarnItem, is_complated ? 'pointer-events-none' : 'pointer-events-auto')} onClick={onClick}>
      <i className={styles.EarnItemImage}>
        <img src={`${BASE_URL}/static/tasks/${task_photo}`} alt={task_name} />
      </i>

      <div className={styles.EarnItemRow}>
        <span className={styles.EarnItemName}>
          {check_type === 'refferals_premium' ? (
            <>
              INVITE <span className="text-[#FFDC4A]">PREMIUM</span> FRIENDS
            </>
          ) : (
            task_name
          )}
        </span>

        <div className={styles.EarnItemColumn}>
          <span className={styles.EarnItemReward}>
            <i className={styles.EarnItemRewardLogo}>
              <img src={require('@assets/img/currencies/ticket.webp')} alt="Ticket" />
            </i>
            + {check_type !== 'refferals_premium' && check_type !== 'refferals' && task_reward}
            {check_type === 'refferals' && settingsResponse?.tickets_per_friend}
            {check_type === 'refferals_premium' && settingsResponse?.tickets_per_premium_friend}
          </span>

          {(check_type === 'refferals_premium' || check_type === 'refferals') && (
            <span className={classNames(styles.EarnItemReward, complete ? 'text-[#B5F748]' : 'text-white')}>
              <i className={styles.EarnItemRewardLogo}>
                <img src={require('@assets/img/menu/friends.webp')} alt="Friends" />
              </i>
              {friends_to_claim}
            </span>
          )}
        </div>
      </div>

      <i className={classNames(styles.EarnItemLogoStatus, complete && styles.EarnItemLogoStatusPulse)}>
        <img
          src={
            is_complated ? require('@assets/img/icons/task-complete.webp') : complete ? require('@assets/img/icons/task-check.webp') : require('@assets/img/icons/task-arrow.webp')
          }
          alt="Status"
        />
      </i>
    </div>
  );
};

export default React.memo(EarnItem);
