import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './MenuItem.module.scss';

import useSound from '@hooks/useSound';

type Props = {
  imageURL: string;
  title: string;
  to: string;
  count?: number;
};

const MenuItem: React.FC<Props> = ({ imageURL, title, to, count }) => {
  const { defaultSound } = useSound();

  return (
    <li className="relative">
      <NavLink className={({ isActive }) => (isActive ? styles.MenuItemActive : styles.MenuItem)} onClick={() => defaultSound()} to={to}>
        <i>
          <img src={imageURL} alt={title} />
        </i>

        {count && count > 0 && <div>{count}</div>}

        <span>{title}</span>
      </NavLink>
    </li>
  );
};

export default React.memo(MenuItem);
