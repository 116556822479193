// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vr_NjukIEzjPi_ZZfexm {
  text-align: center;
}
.vr_NjukIEzjPi_ZZfexm:not(:last-child) {
  margin-bottom: 60px;
}
@media (max-width: 430px) {
  .vr_NjukIEzjPi_ZZfexm:not(:last-child) {
    margin-bottom: calc(36px - -24 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .vr_NjukIEzjPi_ZZfexm:not(:last-child) {
    margin-bottom: 36px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/modals/SettingsModal/SettingsModal.module.scss","webpack://./src/styles/mixins.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;AAAF;AAEE;ECDA,mBAAA;ADEF;ACAE;EDDA;ICKE,uDAAA;EDAF;AACF;ACEE;EDRA;ICSE,mBAAA;EDCF;AACF","sourcesContent":["@use '@styles/mixins.scss' as *;\n.SettingsModalTitle {\r\n  text-align: center;\r\n\r\n  &:not(:last-child) {\r\n    @include fluid(margin-bottom, 60px, 36px);\r\n  }\r\n}\r\n","@use 'sass:math';\r\n\r\n@mixin fluid($property, $max, $min, $min-screen: 320px, $max-screen: 430px) {\r\n  #{$property}: #{$max};\r\n\r\n  @media (max-width: #{$max-screen}) {\r\n    $value-range: math.div($min - $max, 1px);\r\n    $screen-range: math.div($max-screen - $min-screen, 1px);\r\n\r\n    #{$property}: calc(#{$min} - #{$value-range} * (100vw - #{$min-screen}) / #{$screen-range});\r\n  }\r\n\r\n  @media (max-width: #{$min-screen}) {\r\n    #{$property}: #{$min};\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SettingsModalTitle": `vr_NjukIEzjPi_ZZfexm`
};
export default ___CSS_LOADER_EXPORT___;
