// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d44309NxqvEM57TKLj8t {
  margin-bottom: -95px;
}
@media (max-width: 430px) {
  .d44309NxqvEM57TKLj8t {
    margin-bottom: calc(-65px - 30 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .d44309NxqvEM57TKLj8t {
    margin-bottom: -65px;
  }
}
.d44309NxqvEM57TKLj8t:not(:last-child) {
  margin-right: 36px;
}
@media (max-width: 430px) {
  .d44309NxqvEM57TKLj8t:not(:last-child) {
    margin-right: calc(24px - -12 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .d44309NxqvEM57TKLj8t:not(:last-child) {
    margin-right: 24px;
  }
}

.EywP5HzMYlBUQ75nEpgj {
  width: 82px;
  position: relative;
}
@media (max-width: 430px) {
  .EywP5HzMYlBUQ75nEpgj {
    width: calc(58px - -24 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .EywP5HzMYlBUQ75nEpgj {
    width: 58px;
  }
}
.EywP5HzMYlBUQ75nEpgj img {
  max-width: 100%;
}

.UdWQYgrmSidUEtdPVq3D {
  width: 32px;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0px;
  pointer-events: none;
  transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
}
@media (max-width: 430px) {
  .UdWQYgrmSidUEtdPVq3D {
    width: calc(24px - -8 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .UdWQYgrmSidUEtdPVq3D {
    width: 24px;
  }
}
@media (max-width: 430px) {
  .UdWQYgrmSidUEtdPVq3D {
    right: calc(-2px - -2 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .UdWQYgrmSidUEtdPVq3D {
    right: -2px;
  }
}
.UdWQYgrmSidUEtdPVq3D img {
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/modals/SettingsModal/components/Sound/Sound.module.scss","webpack://./src/styles/mixins.scss"],"names":[],"mappings":"AACA;ECEE,oBAAA;ADDF;ACGE;EDJF;ICQI,uDAAA;EDHF;AACF;ACKE;EDXF;ICYI,oBAAA;EDFF;AACF;AARE;ECDA,kBAAA;ADYF;ACVE;EDDA;ICKE,sDAAA;EDUF;AACF;ACRE;EDRA;ICSE,kBAAA;EDWF;AACF;;AAhBA;ECNE,WAAA;EDQA,kBAAA;AAmBF;ACzBE;EDIF;ICAI,+CAAA;EDyBF;AACF;ACvBE;EDHF;ICII,WAAA;ED0BF;AACF;AA3BE;EACE,eAAA;AA6BJ;;AAzBA;ECfE,WAAA;EDiBA,cAAA;EACA,kBAAA;EACA,SAAA;ECnBA,UAAA;EDqBA,oBAAA;EACA,wDAAA;AA4BF;AChDE;EDaF;ICTI,8CAAA;EDgDF;AACF;AC9CE;EDMF;ICLI,WAAA;EDiDF;AACF;AC1DE;EDaF;ICTI,8CAAA;ED0DF;AACF;ACxDE;EDMF;ICLI,WAAA;ED2DF;AACF;AA9CE;EACE,eAAA;AAgDJ","sourcesContent":["@use '@styles/mixins.scss' as *;\n.SoundButton {\r\n  @include fluid(margin-bottom, -95px, -65px);\r\n\r\n  &:not(:last-child) {\r\n    @include fluid(margin-right, 36px, 24px);\r\n  }\r\n}\r\n\r\n.SoundIcon {\r\n  @include fluid(width, 82px, 58px);\r\n  position: relative;\r\n\r\n  img {\r\n    max-width: 100%;\r\n  }\r\n}\r\n\r\n.SoundNO {\r\n  @include fluid(width, 32px, 24px);\r\n  display: block;\r\n  position: absolute;\r\n  bottom: 0;\r\n  @include fluid(right, 0px, -2px);\r\n  pointer-events: none;\r\n  transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;\r\n\r\n  img {\r\n    max-width: 100%;\r\n  }\r\n}\r\n","@use 'sass:math';\r\n\r\n@mixin fluid($property, $max, $min, $min-screen: 320px, $max-screen: 430px) {\r\n  #{$property}: #{$max};\r\n\r\n  @media (max-width: #{$max-screen}) {\r\n    $value-range: math.div($min - $max, 1px);\r\n    $screen-range: math.div($max-screen - $min-screen, 1px);\r\n\r\n    #{$property}: calc(#{$min} - #{$value-range} * (100vw - #{$min-screen}) / #{$screen-range});\r\n  }\r\n\r\n  @media (max-width: #{$min-screen}) {\r\n    #{$property}: #{$min};\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SoundButton": `d44309NxqvEM57TKLj8t`,
	"SoundIcon": `EywP5HzMYlBUQ75nEpgj`,
	"SoundNO": `UdWQYgrmSidUEtdPVq3D`
};
export default ___CSS_LOADER_EXPORT___;
