import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type ITaskModel from '@interfaces/models/TaskModel';
import { type RootState } from '../';

type TypeEarnResponse = {
  count_refs: number;
  tasks: ITaskModel[];
};

interface IEarn {
  response: TypeEarnResponse;

  currentTaskModalID: ITaskModel['_id'];
}

const initialState: IEarn = {
  response: null,

  currentTaskModalID: null,
};

const earnSlice = createSlice({
  name: 'earn',
  initialState,
  reducers: {
    setEarnResponse: (state, { payload }: PayloadAction<TypeEarnResponse>) => {
      state.response = payload;
    },

    setCurrentTaskModalID: (state, { payload }: PayloadAction<IEarn['currentTaskModalID']>) => {
      state.currentTaskModalID = payload;
    },

    setTaskCanComplete: (state, { payload }: PayloadAction<{ _id: ITaskModel['_id']; can: boolean }>) => {
      const task = state.response.tasks.find((task) => task._id === payload._id);

      if (task) {
        task.can_complate = payload.can;
        window.localStorage.setItem('ads-task-complete', String(payload.can));
      }
    },
  },
});

export const { setEarnResponse, setCurrentTaskModalID, setTaskCanComplete } = earnSlice.actions;

export const tasksNotCompletedCount = (state: RootState): number => Number(state.earn.response?.tasks?.filter(({ is_complated }) => !is_complated).length);

export default earnSlice.reducer;
