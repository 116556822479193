import React from 'react';
import classNames from 'classnames';

import styles from './Button.module.scss';

import { type TypeStatus } from 'interfaces/status.types';

import useSound from '@hooks/useSound';

type Props<T = React.ReactNode> = {
  children: T;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  onTouchStart?: () => void;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  status?: TypeStatus;
  size?: '265px' | '224px' | '210px' | '168px';
  sound?: boolean;
};

const Button: React.FC<Props> = ({ children, className, style, onClick, onTouchStart, disabled, type = 'button', status = 'loaded', size = '265px', sound = true }) => {
  const { defaultSound } = useSound();

  return (
    <button
      className={classNames(
        styles.Button,

        className,

        size === '265px' && styles.Button265px,

        size === '210px' && styles.Button210px,

        size === '168px' && styles.Button168px,

        // loading
        status === 'loading' && 'opacity-50 pointer-events-none',

        // error
        'disabled:opacity-50 disabled:pointer-events-none'
      )}
      style={style}
      onClick={() => {
        if (sound) {
          defaultSound();
        }
        onClick?.();
      }}
      onTouchStart={() => onTouchStart?.()}
      type={type}
      disabled={status === 'error' || disabled}
    >
      {children}
    </button>
  );
};

export default React.memo(Button);
