import React from 'react';
import toast from 'react-hot-toast';
import { useForm, SubmitHandler } from 'react-hook-form';

import styles from './WithdrawModal.module.scss';

import type { TypeStatus } from '@interfaces/status.types';

import { API } from '@services/API';

import { toastID, toastDuration, toastText } from '@configs/constants/toast';

import useModal from '@hooks/useModal';

import { useAppDispatch, useAppSelector } from '@store';

import ModalTemplate from '@components/layouts/ModalTemplate';
import Container from '@components/layouts/Container';
import { Button, ModalTitle } from '@components/UI';
import TextField from './components/TextField';
import { setUpgradeUser } from '@store/slices/auth';
import validateTonAddress from '@utils/validateTonAddress';

type TypeValues = {
  address: string;
  amount: number;
};

function WithdrawModal() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const settingsResponse = useAppSelector((state) => state.settings.response);

  const [status, setStatus] = React.useState<TypeStatus>('loaded');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TypeValues>();

  const { setCloseModal } = useModal();

  const onSubmit: SubmitHandler<TypeValues> = async (values) => {
    try {
      setStatus('loading');
      toast.loading(toastText.loading, { id: toastID, className: 'toast-loading' });

      const { address, amount } = values;

      const { data } = await API.post('/withdraw', {
        address,
        amount: Number(amount),
      });

      dispatch(setUpgradeUser(data?.user));

      setCloseModal('withdraw');
      toast.success(toastText.success, { id: toastID, duration: toastDuration, className: 'toast-success' });
    } catch (err) {
      console.log(err);
      setStatus('error');
      const message = err?.response?.data?.error_message || toastText.error;
      toast.error(message, { id: toastID, duration: toastDuration, className: 'toast-error' });
    } finally {
      setStatus('loaded');
    }
  };

  return (
    <ModalTemplate onOpen={() => reset()} name="withdraw">
      <Container>
        <ModalTitle className={styles.WithdrawModalTitle}>withdraw</ModalTitle>

        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
          <div className={styles.WithdrawModalList}>
            <TextField
              label={<span className={styles.WithdrawModalFieldLabelText}>ton address</span>}
              hookForm={register('address', {
                required: 'Field is required',
                onBlur() {
                  window.scrollTo(0, 0);
                },
                validate: (value) => {
                  if (!validateTonAddress(value)) {
                    return 'Invalid address';
                  }
                },
              })}
              error={errors.address}
              placeholder="enter address"
            />

            <TextField
              label={
                <div className={styles.WithdrawModalLabel2}>
                  <span className={styles.WithdrawModalFieldLabelText}>Amount</span>

                  <div className="inline-flex items-center">
                    <span className={styles.WithdrawModalFieldLabelCount}>balance {user?.balances?.balance_ton || 0}</span>

                    <i className={styles.WithdrawModalFieldLabelLogo}>
                      <img src={require('@assets/img/currencies/ton.webp')} alt="TON" />
                    </i>
                  </div>
                </div>
              }
              hookForm={register('amount', {
                required: 'Field is required',
                onBlur() {
                  window.scrollTo(0, 0);
                },
                validate: (value) => {
                  if (value > user?.balances?.balance_ton) {
                    return 'Insufficient funds';
                  }

                  if (value < settingsResponse?.minimum_withdraw) {
                    return `Min amount is ${settingsResponse?.minimum_withdraw} TON`;
                  }
                },
              })}
              error={errors.amount}
              type="number"
              placeholder="enter Amount"
              after={<span className={styles.WithdrawModalFieldAfter2}>min. {settingsResponse?.minimum_withdraw || 0}</span>}
            />
          </div>

          <ul className={styles.WithdrawModalInfo}>
            <li className={styles.WithdrawModalInfoItem}>
              <span className={styles.WithdrawModalInfoTitle}>Amount received</span>

              <div className={styles.WithdrawModalInfoValue}>
                <span>{settingsResponse?.minimum_withdraw || 0}</span>

                <i className={styles.WithdrawModalInfoValueLogo}>
                  <img src={require('@assets/img/currencies/ton.webp')} alt="TON" />
                </i>
              </div>
            </li>

            <li className={styles.WithdrawModalInfoItem}>
              <span className={styles.WithdrawModalInfoTitle}>Fee</span>

              <div className={styles.WithdrawModalInfoValue}>
                <span>{settingsResponse?.withdraw_fee || 0}</span>

                <i className={styles.WithdrawModalInfoValueLogo}>
                  <img src={require('@assets/img/currencies/ton.webp')} alt="TON" />
                </i>
              </div>
            </li>
          </ul>

          <div className={styles.WithdrawModalSubmitContainer}>
            <Button size="210px" status={status} type="submit">
              withdraw
            </Button>
          </div>
        </form>
      </Container>
    </ModalTemplate>
  );
}

export default WithdrawModal;
