import React from 'react';

import styles from './Earn.module.scss';

import type ITaskModel from '@interfaces/models/TaskModel';

import useIsChildAtBottom from '@hooks/useIsChildAtBottom';

import { API } from '@services/API';

import { useAppDispatch, useAppSelector } from '@store';
import { setEarnResponse } from '@store/slices/earn';

import PageTemplate from '@components/layouts/PageTemplate';
import Container from '@components/layouts/Container';
import { UserControls } from '@components/UI';
import EarnDailyReward from './components/EarnDailyReward';
import EarnItem from './components/EarnItem';
import { ClaimTaskModal } from '@components/modals';

function Earn() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const tasksItems = useAppSelector((state) => state.earn.response?.tasks);

  const targetAfterRef = React.useRef<HTMLDivElement | null>(null);

  const [hasReturned, setHasReturned] = React.useState(false);
  const [lastFocusTime, setLastFocusTime] = React.useState<number>(0);

  const pageTemplateAfter = useIsChildAtBottom(targetAfterRef);

  async function getEarn() {
    try {
      if (user) {
        const { data } = await API.post('/tasks/get');

        dispatch(setEarnResponse(data));
      }
    } catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    getEarn();
  }, []);

  React.useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && !hasReturned) {
        getEarn();
        setHasReturned(true);
      }
    };

    const handleFocus = () => {
      const now = Date.now();
      if (!hasReturned && now - lastFocusTime > 500) {
        getEarn();
        setHasReturned(true);
        setLastFocusTime(now);
      }
    };

    const handleBlur = () => {
      setHasReturned(false);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, [hasReturned, lastFocusTime]);

  return (
    <>
      <PageTemplate className="page-template-top" after={pageTemplateAfter}>
        <Container className="flex flex-col flex-auto justify-between">
          <UserControls />

          <div className={styles.EarnContent}>
            <div className={styles.EarnTitle}>Task list</div>

            <div className={styles.EarnList} ref={targetAfterRef}>
              <EarnDailyReward />

              {tasksItems && tasksItems.map((item: ITaskModel, idx: number) => <EarnItem {...item} key={`friend-${idx}`} />)}
            </div>
          </div>
        </Container>
      </PageTemplate>

      <ClaimTaskModal />
    </>
  );
}

export default Earn;
