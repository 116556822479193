import { useDispatch, useSelector, type TypedUseSelectorHook } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import settings from './slices/settings';

import auth from './slices/auth';

import wheel from './slices/wheel';

import marquee from './slices/marquee';

import rating from './slices/rating';

import earn from './slices/earn';

import friends from './slices/friends';

import shop from './slices/shop';

export const store = configureStore({
  reducer: {
    settings,

    auth,

    wheel,

    marquee,

    rating,

    earn,

    friends,

    shop,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
