import React from 'react';
import classNames from 'classnames';

import styles from './Close.module.scss';

import useSound from '@hooks/useSound';

type Props = {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
};

const Close: React.FC<Props> = ({ className, onClick, disabled }) => {
  const { defaultSound } = useSound();

  return (
    <button
      className={classNames(styles.Close, className)}
      onClick={() => {
        onClick?.();
        defaultSound();
      }}
      disabled={disabled}
      type="button"
    >
      <img src={require('@assets/img/icons/close.webp')} alt="Close" />
    </button>
  );
};

export default React.memo(Close);
