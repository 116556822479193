// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@assets/fonts/Foo/Regular/Foo-Regular.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@assets/fonts/Foo/Regular/Foo-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@assets/fonts/Foo/Regular/Foo-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Foo";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  src: local("@assets/fonts/Foo/Regular/Foo-Regular"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("embedded-opentype"), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
  font-weight: 400;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/styles/fonts.css"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,4CAAA;EACA,4OAAA;EAEA,gBAAA;EACA,kBAAA;AADF","sourcesContent":["@use '@styles/mixins.scss' as *;\n@font-face {\r\n  font-family: 'Foo';\r\n  src: url('@assets/fonts/Foo/Regular/Foo-Regular.eot');\r\n  src: local('@assets/fonts/Foo/Regular/Foo-Regular'), url('@assets/fonts/Foo/Regular/Foo-Regular.eot?#iefix') format('embedded-opentype'),\r\n    url('@assets/fonts/Foo/Regular/Foo-Regular.woff') format('woff'), url('@assets/fonts/Foo/Regular/Foo-Regular.ttf') format('truetype');\r\n  font-weight: 400;\r\n  font-style: normal;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
