import React from 'react';
import classNames from 'classnames';

import styles from './TemplateItem.module.scss';

type Props = {
  title?: string;
  children?: React.ReactNode;
  className?: string;
};

const TemplateItem: React.FC<Props> = ({ title, children, className }) => {
  return (
    <div className={classNames(styles.TemplateItem, className)}>
      {title && <div className={styles.TemplateItemTitle}>{title}</div>}

      <div className={styles.TemplateItemInner}>{children}</div>
    </div>
  );
};

export default React.memo(TemplateItem);
