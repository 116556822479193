import React from 'react';

import styles from './BalanceItem.module.scss';

import useSound from '@hooks/useSound';

type Props = {
  onClick: () => void;
  name: string;
  count: number;
  leftImageURL: string;
  rightImageURL: string;
};

const BalanceItem: React.FC<Props> = ({ onClick, name, count = 0, leftImageURL, rightImageURL }) => {
  const { defaultSound } = useSound();

  return (
    <div
      className={styles.BalanceItem}
      onClick={() => {
        defaultSound();
        onClick();
      }}
    >
      <i className={styles.BalanceItemLeftImage}>
        <img src={leftImageURL} alt={name} />
      </i>

      <span className={styles.BalanceItemCount}>{count}</span>

      <i className={styles.BalanceItemRightImage}>
        <img src={rightImageURL} alt={name} />
      </i>
    </div>
  );
};

export default React.memo(BalanceItem);
