import React from 'react';

import styles from './Marquee.module.scss';

import { type TypeMarqueeItem } from '@store/slices/marquee';

type Props = TypeMarqueeItem;

const MarqueeItem: React.FC<Props> = ({ sector, username }) => {
  const currencyName = sector.name.toLowerCase();

  return (
    currencyName !== 'none' && (
      <div className={styles.MarqueeItem}>
        <span className={styles.MarqueeItemText1}>{username}</span>

        <span className={styles.MarqueeItemText2}>{sector.count}</span>

        <i className={styles.MarqueeItemLogo}>
          <img src={require(`@assets/img/currencies/${currencyName}.webp`)} alt={sector.name} />
        </i>
      </div>
    )
  );
};

export default React.memo(MarqueeItem);
