import axios, { type AxiosInstance } from 'axios';

export const initDataString =
  'query_id=AAEWgtYsAgAAABaC1iwaQwSD&user=%7B%22id%22%3A5047222806%2C%22first_name%22%3A%22%D0%94%D0%BC%D0%B8%D1%82%D1%80%D0%B8%D0%B9%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22holymolly_developer%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%2C%22photo_url%22%3A%22https%3A%5C%2F%5C%2Ft.me%5C%2Fi%5C%2Fuserpic%5C%2F320%5C%2FRo6DoEFpONhnkuKcpZIRrHUMS6ciiGVkVkKI69HcuabPrpnMYxkJVSXlKtFYwtkD.svg%22%7D&auth_date=1735021174&signature=7Xx1YONNRkkhgqCi6ZTM9oAKK69Fry4kx2GO3lQGtCwtNODM7NB9rE__ffRmWuKSwimVxdepVDB-iaH3hdp-DA&hash=b09c6e894004660af9968cc02203b4b5be9d73a2a263539306d0a76d01518b5e';

export const DOMAIN_SERVER: string = 'spin-api.m-bot.site';

export const BASE_URL: string = `https://${DOMAIN_SERVER}`;

export let token: string = '';

export const setAuthToken = (newToken: string) => {
  token = newToken;
};

export const API: AxiosInstance = axios.create({
  baseURL: `${BASE_URL}/api`,
});

API.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${token}`;

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);
