import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { TypeStatus } from '@interfaces/status.types';
import type ISettingsModel from '@interfaces/models/SettingsModel';

export type TypeModals = 'withdraw' | 'daily-tickets' | 'no-tickets' | 'claim-task' | 'settings';

export const preloaderTime: number = 1500;

interface ISettings {
  preloaderStatus: TypeStatus;

  active_modal: TypeModals[];

  is_sound: boolean;

  response: ISettingsModel;
}

const localSound: boolean = Boolean(JSON.parse(window.localStorage.getItem('is-sound')) || false);

const initialState: ISettings = {
  preloaderStatus: 'loading',

  active_modal: [],

  is_sound: localSound,

  response: null,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setPreloaderStatus: (state, { payload }: PayloadAction<ISettings['preloaderStatus']>) => {
      state.preloaderStatus = payload;
    },

    // Функция открытия/закрытия модального окна
    setActiveModal: (state, { payload }: PayloadAction<TypeModals[] | []>) => {
      state.active_modal = payload;
    },

    setSound: (state, { payload }: PayloadAction<ISettings['is_sound']>) => {
      state.is_sound = payload;
      window.localStorage.setItem('is-sound', String(payload));
    },

    setSettingsResponse: (state, { payload }: PayloadAction<ISettingsModel>) => {
      state.response = payload;
    },
  },
});

export const { setPreloaderStatus, setActiveModal, setSound, setSettingsResponse } = settingsSlice.actions;

export default settingsSlice.reducer;
