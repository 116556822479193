import React from 'react';

export interface useAdsgramParams {
  blockId: string;
  onReward?: () => void;
  onError?: (result: any) => void;
}

type TypeUseAdsgramReturn = () => Promise<void>;

function useAdsgram({ blockId, onReward, onError }: useAdsgramParams): TypeUseAdsgramReturn {
  const AdControllerRef = React.useRef<any | undefined>(undefined);

  React.useEffect(() => {
    AdControllerRef.current = (window as any).Adsgram?.init({ blockId, debugBannerType: 'FullscreenMedia' });
  }, [blockId]);

  return React.useCallback(async () => {
    if (AdControllerRef.current) {
      AdControllerRef.current
        .show()
        .then(() => {
          onReward?.();
        })
        .catch((result: any) => {
          onError?.(result);
        });
    }
  }, [onError, onReward]);
}

export default useAdsgram;
