import React from 'react';

import styles from './Friends.module.scss';

import { API } from '@services/API';

import useIsChildAtBottom from '@hooks/useIsChildAtBottom';

import { useAppDispatch, useAppSelector } from '@store';
import { setFriendsResponse, type TypeFriend } from '@store/slices/friends';

import PageTemplate from '@components/layouts/PageTemplate';
import Container from '@components/layouts/Container';
import { UserControls } from '@components/UI';
import ShareButtons from './components/ShareButtons';
import FriendItem from './components/FriendItem';

function Friends() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const friendsResponse = useAppSelector((state) => state.friends.response);

  const targetAfterRef = React.useRef<HTMLDivElement | null>(null);

  const pageTemplateAfter = useIsChildAtBottom(targetAfterRef);

  async function getFriends() {
    try {
      if (!user) return;

      const { data } = await API.post('/refferers');

      dispatch(setFriendsResponse(data));
    } catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    getFriends();
  }, []);

  return (
    <PageTemplate className="page-template-top" after={pageTemplateAfter}>
      <Container className="flex flex-col flex-auto justify-between">
        <UserControls />

        <div className={styles.FriendsContent}>
          <div className={styles.FriendsTitle}>
            You can <span>earn tickets</span> by inviting friends to the Lucky Spin app
          </div>

          <ShareButtons />

          {friendsResponse?.refferers?.length > 0 ? (
            <>
              <div className={styles.FriendsTitleList}>
                {friendsResponse?.count_refs} Friends (<span className="text-[#FFDC4A_!important]">{friendsResponse?.count_premium_refs} Premium</span>)
              </div>

              <div className={styles.FriendsList} ref={targetAfterRef}>
                {friendsResponse?.refferers.map((friend: TypeFriend, idx: number) => (
                  <FriendItem {...friend} key={`friend-${idx}`} />
                ))}
              </div>
            </>
          ) : (
            <div className={styles.FriendsTitleNoList}>
              Invite friends using your <span>referral link</span> above
            </div>
          )}
        </div>
      </Container>
    </PageTemplate>
  );
}

export default Friends;
