import React from 'react';

import styles from './Shop.module.scss';

import { API } from '@services/API';

import useIsChildAtBottom from '@hooks/useIsChildAtBottom';

import { useAppDispatch, useAppSelector } from '@store';
import { setShopResponse, type TypeShopItem } from '@store/slices/shop';

import PageTemplate from '@components/layouts/PageTemplate';
import Container from '@components/layouts/Container';
import { UserControls } from '@components/UI';
import ShopStarItem from './components/ShopStarItem';

function Shop() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const shopResponse = useAppSelector((state) => state.shop.response);

  const targetAfterRef = React.useRef<HTMLDivElement | null>(null);

  const pageTemplateAfter = useIsChildAtBottom(targetAfterRef);

  async function getShop() {
    try {
      if (!user) return;

      const { data } = await API.post('/shop/get_items');

      dispatch(setShopResponse(data.shop_items));
    } catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    getShop();
  }, []);

  return (
    <PageTemplate className="page-template-top" after={pageTemplateAfter}>
      <Container className="flex flex-col flex-auto justify-between">
        <UserControls />

        {/* <div className={styles.Shop}>
          <div className={styles.ShopImage}>
            <img src={require('@assets/img/shop.webp')} alt="Shop" />
          </div>

          <div className={styles.ShopImageTitle}>Coming soon...</div>
        </div> */}

        <div className={styles.ShopContent}>
          <div className={styles.ShopTitle}>Shop</div>

          <div className={styles.ShopList} ref={targetAfterRef}>
            {shopResponse && shopResponse.map((item: TypeShopItem, idx: number) => <ShopStarItem {...item} key={`shop-star-${idx}`} />)}
          </div>
        </div>
      </Container>
    </PageTemplate>
  );
}

export default Shop;
