import styles from './Error.module.scss';

import { useAppSelector } from '@store';

import PageTemplate from '../PageTemplate';
import Container from '../Container';

function Error() {
  const userStatus = useAppSelector((state) => state.auth.userStatus);

  return (
    <PageTemplate className="w-full h-full absolute z-[50]" style={{ display: userStatus === 'error' ? 'flex' : 'none' }}>
      <Container className={styles.ErrorContainer}>
        <div className={styles.ErrorBox}>
          <div className={styles.ErrorImage}>
            <img src={require('@assets/img/network-error.webp')} alt="Preloader" />
          </div>

          <div className={styles.ErrorText}>network error</div>
        </div>
      </Container>
    </PageTemplate>
  );
}

export default Error;
