import styles from './Maintenance.module.scss';

import { useAppSelector } from '@store';

import PageTemplate from '../PageTemplate';
import Container from '../Container';

function Maintenance() {
  const is_tech_stop = useAppSelector((state) => state.settings.response?.is_tech_stop);

  return (
    <PageTemplate className="w-full h-full absolute z-[40]" style={{ display: is_tech_stop ? 'flex' : 'none' }}>
      <Container className={styles.MaintenanceContainer}>
        <div className={styles.MaintenanceBox}>
          <div className={styles.MaintenanceImage}>
            <img src={require('@assets/img/maintenance.webp')} alt="Maintenance" />
          </div>

          <div className={styles.MaintenanceTitle}>Maintenance</div>

          <div className={styles.MaintenanceText}>come back later</div>
        </div>
      </Container>
    </PageTemplate>
  );
}

export default Maintenance;
