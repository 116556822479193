import DailyTicketsModal from './DailyTicketsModal';

import NoTicketsModal from './NoTicketsModal';

import WithdrawModal from './WithdrawModal';

import ClaimTaskModal from './ClaimTaskModal';

import SettingsModal from './SettingsModal';

export { DailyTicketsModal, NoTicketsModal, WithdrawModal, ClaimTaskModal, SettingsModal };
