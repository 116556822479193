import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type TypeShopItem = {
  count_tickets: number;
  price_stars: number;
  _id: string;
};

type TypeShopResponse = TypeShopItem[];

interface IShop {
  response: TypeShopResponse;
}

const initialState: IShop = {
  response: null,
};

const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    setShopResponse: (state, { payload }: PayloadAction<TypeShopResponse>) => {
      state.response = payload;
    },
  },
});

export const { setShopResponse } = shopSlice.actions;

export default shopSlice.reducer;
