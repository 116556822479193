import React from 'react';

import { useAppDispatch, useAppSelector } from '@store';
import { setActiveModal, type TypeModals } from '@store/slices/settings';

type TypeUseModalReturn = {
  setOpenModal: (value: TypeModals) => void;
  setCloseModal: (value?: TypeModals) => void;
};

function useModal(): TypeUseModalReturn {
  const dispatch = useAppDispatch();
  const active_modal = useAppSelector((state) => state.settings.active_modal);

  const setOpenModal = React.useCallback(
    (value: TypeModals) => {
      dispatch(setActiveModal([...active_modal, value]));
    },
    [active_modal]
  );

  const setCloseModal = React.useCallback(
    (value?: TypeModals) => {
      if (value) {
        const arrModals = active_modal.filter((item: TypeModals) => {
          return item !== value;
        });

        dispatch(setActiveModal(arrModals));
      } else {
        dispatch(setActiveModal([]));
      }
    },
    [active_modal]
  );

  return {
    setOpenModal,
    setCloseModal,
  };
}

export default useModal;
