import React from 'react';
import toast from 'react-hot-toast';

import styles from './DailyTicketsModal.module.scss';

import type { TypeStatus } from '@interfaces/status.types';

import useModal from '@hooks/useModal';
import useEvent from '@hooks/useEvent';

import { API } from '@services/API';

import { toastID, toastDuration, toastText } from '@configs/constants/toast';

import { useAppDispatch, useAppSelector } from '@store';
import { setUpgradeUser } from '@store/slices/auth';

import ModalTemplate from '@components/layouts/ModalTemplate';
import { Button, ModalTitle } from '@components/UI';

function DailyTicketsModal() {
  const dispatch = useAppDispatch();
  const settingsResponse = useAppSelector((state) => state.settings.response);

  const [status, setStatus] = React.useState<TypeStatus>('loaded');

  const { setCloseModal } = useModal();
  const event = useEvent();

  const onClaim = React.useCallback(async () => {
    try {
      setStatus('loading');
      toast.loading(toastText.loading, { id: toastID, className: 'toast-loading' });

      const { data } = await API.post('/claim-daily');

      toast.success(toastText.success, { id: toastID, duration: toastDuration, className: 'toast-success' });
      setCloseModal('daily-tickets');

      dispatch(setUpgradeUser(data?.user));

      // event({
      //   event_data: {
      //     screen: 'Home',
      //     source: 'DailyTickets',
      //     type: 'daily',
      //   },
      // });
    } catch (err) {
      console.log(err);
      setStatus('error');
      const message = err?.response?.data?.error_message || toastText.error;
      toast.error(message, { id: toastID, duration: toastDuration, className: 'toast-error' });
    } finally {
      setStatus('loaded');
    }
  }, []);

  return (
    <ModalTemplate name="daily-tickets" type="wood-center">
      <ModalTitle className={styles.DailyTicketsModalTitle}>
        Claim free <br /> daily tickets
      </ModalTitle>

      <div className={styles.DailyTicketsModalRow}>
        <i className={styles.DailyTicketsModalTicketLogo}>
          <img src={require('@assets/img/currencies/ticket.webp')} alt="Ticket" />
        </i>

        <span className={styles.DailyTicketsModalTicketCount}>+{settingsResponse?.daily_tickets_reward}</span>
      </div>

      <Button className="mx-auto" onClick={onClaim} status={status} size="168px">
        Claim
      </Button>
    </ModalTemplate>
  );
}

export default DailyTicketsModal;
