import React from 'react';
import toast from 'react-hot-toast';
import classNames from 'classnames';

import styles from './ShopStarItem.module.scss';

import type { TypeStatus } from '@interfaces/status.types';

import useSound from '@hooks/useSound';

import { API } from '@services/API';

import { toastDuration, toastID, toastText } from '@configs/constants/toast';

import { type TypeShopItem } from '@store/slices/shop';
import { useAppDispatch, useAppSelector } from '@store';
import { setUpgradeUser } from '@store/slices/auth';

type Props = TypeShopItem;

const ShopStarItem: React.FC<Props> = ({ count_tickets, price_stars, _id }) => {
  const dispatch = useAppDispatch();
  const balances = useAppSelector((state) => state.auth.user?.balances);

  const webApp = window.Telegram.WebApp;

  const [status, setStatus] = React.useState<TypeStatus>('loaded');

  const { defaultSound } = useSound();

  const onClick = React.useCallback(async () => {
    try {
      defaultSound();

      setStatus('loading');

      const { data } = await API.post('/shop/create_invoice', { item_id: _id });

      webApp.openInvoice(data?.link, async (e) => {
        if (e === 'paid' || e === 'pending') {
          dispatch(
            setUpgradeUser({
              balances: {
                ...balances,
                balance_tickets: balances?.balance_tickets + count_tickets,
              },
            })
          );

          toast.success('Purchase succeeded', { id: toastID, duration: toastDuration, className: 'toast-success' });
        } else if (e === 'failed') {
          toast.error('Purchase failed', { id: toastID, duration: toastDuration, className: 'toast-error' });
        }
      });
    } catch (err) {
      console.log(err);
      setStatus('error');
      const message = err?.response?.data?.error_message || toastText.error;
      toast.error(message, { id: toastID, duration: toastDuration, className: 'toast-error' });
    } finally {
      setStatus('loaded');
    }
  }, []);

  return (
    <div className={classNames(styles.ShopStarItem, status !== 'loaded' ? 'pointer-events-none' : 'pointer-events-auto')} onClick={onClick}>
      <div className={styles.ShopStarItemRow}>
        <i className={styles.ShopStarItemStar}>
          <img src={require('@assets/img/icons/star.webp')} alt="Ticket" />
        </i>

        <span className={styles.ShopStarItemValue}>{price_stars}</span>
      </div>

      <div className={styles.ShopStarItemRow}>
        <span className={styles.ShopStarItemValue}>+{count_tickets}</span>

        <i className={styles.ShopStarItemTicket}>
          <img src={require('@assets/img/currencies/ticket.webp')} alt="Ticket" />
        </i>
      </div>
    </div>
  );
};

export default React.memo(ShopStarItem);
