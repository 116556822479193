import React from 'react';

import styles from './TopUser.module.scss';

import { type TypeTopUser } from '@store/slices/rating';

type Props = TypeTopUser;

const TopUser: React.FC<Props> = ({ position, username, balance }) => {
  return (
    <div className={styles.TopUser}>
      <span className={styles.TopUserPosition}>{position}</span>

      <span className={styles.TopUserName}>{username}</span>

      <div className={styles.TopUserCurrency}>
        <span className={styles.TopUserCurrencyCount}>{balance}</span>

        <i className={styles.TopUserCurrencyLogo}>
          <img src={require('@assets/img/currencies/coin.webp')} alt="Coin" />
        </i>
      </div>
    </div>
  );
};

export default React.memo(TopUser);
