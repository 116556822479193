import React from 'react';
import classNames from 'classnames';

import styles from '../EarnItem/EarnItem.module.scss';

import useModal from '@hooks/useModal';
import useSound from '@hooks/useSound';

import getUTCTimeToNewDay from '@utils/getUTCTimeToNewDay';

import { useAppDispatch, useAppSelector } from '@store';
import { setResetDailyReward } from '@store/slices/auth';

const EarnDailyReward: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const settingsResponse = useAppSelector((state) => state.settings.response);

  const time: number = 24;

  const onTime = () => {
    dispatch(setResetDailyReward());
  };

  const { defaultSound } = useSound();
  const { setOpenModal } = useModal();

  const [formattedTime, setFormattedTime] = React.useState<string>('--:--:--');

  const updateCountdown = React.useCallback(() => {
    if (!time) return;

    const timeCounter = getUTCTimeToNewDay(time);

    setFormattedTime(timeCounter);

    return 1000;
  }, []);

  React.useEffect(() => {
    if (!time) return;

    const intervalID = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalID);
  }, [time, updateCountdown]);

  React.useEffect(() => {
    if (!time) return;

    updateCountdown();
  }, [time]);

  React.useEffect(() => {
    if (formattedTime === '00:00:00') {
      onTime();
    }
  }, [formattedTime]);

  return (
    <div
      className={classNames(styles.EarnItem, user?.is_free_tickets_claimed ? 'pointer-events-none' : 'pointer-events-auto')}
      onClick={() => {
        defaultSound();
        setOpenModal('daily-tickets');
      }}
    >
      <i className={styles.EarnItemImage}>
        <img src={require('@assets/img/menu/earn.webp')} alt="Daily reward" />
      </i>

      <div className={styles.EarnItemRow}>
        <span className={styles.EarnItemName}>Daily tickets</span>

        <div className={styles.EarnItemColumn}>
          <span className={styles.EarnItemReward}>
            <i className={styles.EarnItemRewardLogo}>
              <img src={require('@assets/img/currencies/ticket.webp')} alt="Ticket" />
            </i>
            + {settingsResponse?.daily_tickets_reward}
          </span>
        </div>
      </div>

      {user?.is_free_tickets_claimed ? (
        <span className={styles.EarnItemName}>{formattedTime}</span>
      ) : (
        <i className={classNames(styles.EarnItemLogoStatus, !user?.is_free_tickets_claimed && styles.EarnItemLogoStatusPulse)}>
          <img src={require('@assets/img/icons/task-check.webp')} alt="Status" />
        </i>
      )}
    </div>
  );
};

export default React.memo(EarnDailyReward);
