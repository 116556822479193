import React from 'react';

import styles from './Rating.module.scss';

import useIsChildAtBottom from '@hooks/useIsChildAtBottom';

import { API } from '@services/API';

import { useAppDispatch, useAppSelector } from '@store';
import { type TypeTopUser, setRatingResponse } from '@store/slices/rating';

import PageTemplate from '@components/layouts/PageTemplate';
import Container from '@components/layouts/Container';
import { UserControls } from '@components/UI';
import TopUser from './components/TopUser';

function Rating() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const userPosition = useAppSelector((state) => state.rating.response?.user_position);
  const ratingItems = useAppSelector((state) => state.rating.response?.top_users);

  const targetAfterRef = React.useRef<HTMLDivElement | null>(null);

  const pageTemplateAfter = useIsChildAtBottom(targetAfterRef);

  async function getRating() {
    try {
      if (!user) return;

      const { data } = await API.post('/rating');

      dispatch(setRatingResponse(data));
    } catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    getRating();
  }, []);

  return (
    <PageTemplate className="page-template-top" after={pageTemplateAfter}>
      <Container className="flex flex-col flex-auto justify-between">
        <UserControls />

        <div className="flex flex-col items-center flex-auto">
          <div className={styles.RatingCup}>
            <div className={styles.RatingCupImage}>
              <img src={require('@assets/img/cup.webp')} alt="Cup" />
            </div>

            <div className={styles.RatingCupCount}>Rank {userPosition || 0}</div>
          </div>

          {ratingItems && ratingItems.length > 0 && (
            <div className={styles.RatingList} ref={targetAfterRef}>
              {ratingItems.map((item: TypeTopUser, idx: number) => (
                <TopUser {...item} key={`top-user-${idx}`} />
              ))}
            </div>
          )}
        </div>
      </Container>
    </PageTemplate>
  );
}

export default Rating;
