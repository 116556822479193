import styles from './SettingsModal.module.scss';

import ModalTemplate from '@components/layouts/ModalTemplate';
import Container from '@components/layouts/Container';
import { ModalTitle } from '@components/UI';
import { Sound } from './components';

function SettingsModal() {
  return (
    <ModalTemplate name="settings">
      <Container>
        <ModalTitle className={styles.SettingsModalTitle}>settings</ModalTitle>

        <Sound />
      </Container>
    </ModalTemplate>
  );
}

export default SettingsModal;
