export default class WheelOfFortune {
  constructor(element, labels) {
    this.animationSpins = 5; // Количество полных оборотов
    this.animationTime = 3000; // Время анимации в миллисекундах
    this.element = element;
    this.labels = labels;
    this.container = this._createElement('div', 'wheel-of-fortune__container');
    this.element.appendChild(this.container);
    this.degreesPerSegment = 360 / labels.length; // Градусы на один сегмент
    this.currentRotation = 0; // Текущий угол поворота
    this.isLocked = false; // Флаг блокировки вращения

    // Создание меток
    this.labels.forEach((label, index) => this._createLabel(label, index));

    // Добавление стрелки
    this.element.appendChild(this._createElement('div', 'wheel-of-fortune__arrow'));
  }

  _createLabel(label, index) {
    const labelNode = this._createElement('div', 'wheel-of-fortune__label');
    const labelText = this._createElement('div', 'wheel-of-fortune__label-text', label);

    labelNode.style.transform = `rotate(${this.degreesPerSegment * index}deg)`;
    labelNode.appendChild(labelText);
    this.container.appendChild(labelNode);
  }

  spin(targetIndex) {
    if (this.isLocked) return Promise.resolve();

    this.isLocked = true;

    const targetRotation = -(this.degreesPerSegment * targetIndex); // Точный угол на сегмент
    const fullRotation = 360 * this.animationSpins; // Полные обороты для плавности
    const overshootRotation = targetRotation - fullRotation - 15; // Немного за выбранный сегмент
    const finalRotation = targetRotation - fullRotation;

    const preRotation = this.currentRotation + this.degreesPerSegment * 0.7;
    const preAnimationTime = this.animationTime * 0.1;

    return new Promise((resolve) => {
      // Предварительная анимация
      this._animate(preAnimationTime, (progress) => {
        const rotation = this.currentRotation + (preRotation - this.currentRotation) * easeInOut(progress);
        this.container.style.transform = `rotate(${rotation}deg)`;
      })
        .then(() => {
          // Основная анимация
          return this._animate(this.animationTime, (progress) => {
            const rotation = preRotation + (overshootRotation - preRotation) * easeInOut(progress);
            this.container.style.transform = `rotate(${rotation}deg)`;
          });
        })
        .then(() => {
          // Возврат к целевому сегменту
          // return this._animate(this.animationTime * 0.2, (progress) => {
          //   const rotation = overshootRotation + (finalRotation - overshootRotation) * easeInOut(progress);
          //   this.container.style.transform = `rotate(${rotation}deg)`;
          // });

          return this._animate(preAnimationTime, (progress) => {
            const rotation = overshootRotation + (finalRotation - overshootRotation) * easeInOut(progress);
            this.container.style.transform = `rotate(${rotation}deg)`;
          });
        })
        .then(() => {
          this.currentRotation = finalRotation % 360; // Сохранение остаточного угла
          this.container.style.transform = `rotate(${this.currentRotation}deg)`;
          this.isLocked = false;
          resolve();
        });
    });
  }

  _animate(duration, onProgress) {
    return new Promise((resolve) => {
      const startTime = performance.now();

      const frame = (currentTime) => {
        const progress = Math.min((currentTime - startTime) / duration, 1);
        onProgress(progress);

        if (progress < 1) {
          requestAnimationFrame(frame);
        } else {
          resolve();
        }
      };

      requestAnimationFrame(frame);
    });
  }

  _createElement(type, className, innerHTML = '') {
    const element = document.createElement(type);
    element.className = className;
    if (innerHTML) element.innerHTML = innerHTML;
    return element;
  }
}

// Вспомогательные функции
function easeInOut(time) {
  return 0.5 * (1 - Math.cos(Math.PI * time));
}

// function easeOut(time) {
//   return 1 - Math.pow(1 - time, 3);
// }
