import React from 'react';
import classNames from 'classnames';

import styles from './ModalTemplate.module.scss';

import useModal from '@hooks/useModal';

import { useAppSelector } from '@store';
import { type TypeModals } from '@store/slices/settings';

import { Close } from '@components/UI';

type Props = {
  name: TypeModals;
  type?: 'bottom' | 'wood-center';
  children: React.ReactNode;

  onOpen?: () => void;
};

function ModalTemplate({ name, type = 'bottom', children, onOpen }: Props) {
  const active_modal = useAppSelector((state) => state.settings.active_modal);

  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  const isActiveModal = React.useMemo(() => {
    return active_modal.find((modal: TypeModals) => modal === name);
  }, [active_modal, name]);

  const { setCloseModal } = useModal();

  const onCloseModal = React.useCallback(() => {
    setCloseModal(name);
  }, [name]);

  const indexArr: TypeModals[] = active_modal;

  // Функция закрытия модального окна при клике вне его области
  const onClickOutside = React.useCallback(
    (e: MouseEvent) => {
      if (wrapperRef?.current && wrapperRef?.current.contains(e.target as Node)) {
        onCloseModal();
      }
    },
    [wrapperRef?.current, onCloseModal]
  );

  React.useEffect(() => {
    if (isActiveModal && onOpen) {
      onOpen();
    }

    if (!isActiveModal) return;

    const body = window.document.body;

    body.addEventListener('click', onClickOutside);

    return () => {
      body.removeEventListener('click', onClickOutside);
    };
  }, [active_modal, isActiveModal]);

  return (
    <>
      {type === 'bottom' && (
        <div
          className={classNames(
            styles.ModalTemplateBottom,

            isActiveModal ? 'pointer-events-auto' : 'pointer-events-none'
          )}
          style={{ zIndex: `${isActiveModal ? indexArr.indexOf(name, 1) + 22 : 28}` }}
        >
          <div
            className={classNames(
              styles.ModalTemplateBottomBody,

              isActiveModal ? 'translate-y-[0%]' : 'translate-y-[110%]'
            )}
          >
            {children}

            <Close className={styles.ModalTemplateBottomClose} onClick={onCloseModal} />
          </div>

          <div className={classNames(styles.ModalTemplateWrapper, styles.ModalTemplateBottomWrapper)} ref={wrapperRef} />
        </div>
      )}

      {type === 'wood-center' && (
        <div
          className={classNames(styles.ModalTemplateCenter, isActiveModal ? 'pointer-events-auto' : 'pointer-events-none')}
          style={{ zIndex: `${isActiveModal ? indexArr.indexOf(name, 1) + 22 : 28}` }}
        >
          <div
            className={classNames(
              styles.ModalTemplateCenterBody,

              isActiveModal ? 'scale-[1] opacity-100' : 'scale-[0.8] opacity-0'
            )}
          >
            {children}

            <Close className={styles.ModalTemplateCenterClose} onClick={onCloseModal} />
          </div>

          <div className={classNames(styles.ModalTemplateWrapper, styles.ModalTemplateCenterWrapper, isActiveModal ? 'opacity-100' : 'opacity-0')} ref={wrapperRef} />
        </div>
      )}
    </>
  );
}

export default ModalTemplate;
