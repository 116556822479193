import { type TypeRouter } from './router.types';

import paths from './paths';

import { Home, Rating, Earn, Friends, Shop } from '@pages';

const routes: TypeRouter[] = [
  {
    path: paths.Home,
    element: <Home />,
    title: 'Home',
  },

  {
    path: paths.Rating,
    element: <Rating />,
    title: 'Rating',
  },

  {
    path: paths.Earn,
    element: <Earn />,
    title: 'Earn',
  },

  {
    path: paths.Friends,
    element: <Friends />,
    title: 'Friends',
  },

  {
    path: paths.Shop,
    element: <Shop />,
    title: 'Shop',
  },
];

export default routes;
