import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { TypeCurrency } from '@interfaces/currencies.types';

export type TypeMarqueeItem = {
  sector: {
    count: number;
    name: TypeCurrency;
    weight: number;
  };
  username: string;
};

interface IMarquee {
  marqueeItems: TypeMarqueeItem[];
}

const initialState: IMarquee = {
  marqueeItems: [],
};

const marqueeSlice = createSlice({
  name: 'marquee',
  initialState,
  reducers: {
    setMarqueeResponse: (state, { payload }: PayloadAction<IMarquee['marqueeItems']>) => {
      state.marqueeItems = payload;
    },
  },
});

export const { setMarqueeResponse } = marqueeSlice.actions;

export default marqueeSlice.reducer;
