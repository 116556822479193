import React from 'react';

import styles from './Preloader.module.scss';

import { useAppDispatch, useAppSelector } from '@store';
import { preloaderTime, setPreloaderStatus } from '@store/slices/settings';

import PageTemplate from '../PageTemplate';

const imagesArr = [
  require('@assets/img/bg/main.webp'),

  require('@assets/img/preloader.webp'),
  require('@assets/img/network-error.webp'),

  // Menu
  require('@assets/img/menu.webp'),
  require('@assets/img/menu/rating.webp'),
  require('@assets/img/menu/earn.webp'),
  require('@assets/img/menu/wheel.webp'),
  require('@assets/img/menu/friends.webp'),
  require('@assets/img/menu/shop.webp'),

  // Wheel
  require('@assets/img/wheel/wheel-bg.webp'),
  require('@assets/img/wheel/border.webp'),
  require('@assets/img/wheel/bg-arrow.webp'),
  require('@assets/img/wheel/reward-effect.webp'),
  require('@assets/img/wheel/lose-effect.webp'),

  require('@assets/img/button.webp'),

  require('@assets/img/card-1.webp'),
  require('@assets/img/card-2.webp'),
  require('@assets/img/card-3.webp'),
  require('@assets/img/position.webp'),

  // Emoji
  require('@assets/img/emoji/lose.webp'),

  // Currencies
  require('@assets/img/currencies/coin.webp'),
  require('@assets/img/currencies/ton.webp'),
  require('@assets/img/currencies/ticket.webp'),

  // Icons
  require('@assets/img/icons/settings.webp'),
  require('@assets/img/icons/add.webp'),
  require('@assets/img/icons/up.webp'),
  require('@assets/img/icons/close.webp'),
  require('@assets/img/icons/copy.webp'),
  require('@assets/img/icons/task-arrow.webp'),
  require('@assets/img/icons/task-check.webp'),
  require('@assets/img/icons/sound.webp'),
  require('@assets/img/icons/star.webp'),

  require('@assets/img/cup.webp'),

  require('@assets/img/shop.webp'),

  require('@assets/img/wood-modal.webp'),
];

function Preloader() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const preloaderStatus = useAppSelector((state) => state.settings.preloaderStatus);

  const [loadingImages, setLoadingImages] = React.useState<boolean>(true);

  React.useEffect(() => {
    const images = document.querySelectorAll('img');
    const totalImages = images.length;
    let loadedImages = 0;

    const handleImageLoad = () => {
      loadedImages++;
      if (loadedImages === totalImages) {
        setLoadingImages(false); // Все изображения загружены, скрываем прелоадер
      }
    };

    images.forEach((image) => {
      if (image.complete) {
        handleImageLoad();
      } else {
        image.addEventListener('load', handleImageLoad);
      }
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener('load', handleImageLoad);
      });
    };
  }, []);

  React.useEffect(() => {
    if (loadingImages) return;

    if (!user) return;

    setTimeout(() => {
      dispatch(setPreloaderStatus('loaded'));
    }, preloaderTime);
  }, [user, loadingImages]);

  return (
    <>
      <PageTemplate className="w-full h-full absolute z-[45]" style={{ display: preloaderStatus === 'loading' ? 'flex' : 'none' }}>
        <div className={styles.PreloaderContainer}>
          <div className={styles.PreloaderImage}>
            <img src={require('@assets/img/preloader.webp')} alt="Preloader" />
          </div>

          <div className={styles.PreloaderText}>
            Loading
            <span className={styles.PreloaderTextDots}>
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </span>
          </div>
        </div>
      </PageTemplate>

      <div className="hidden">{imagesArr && imagesArr.map((imageURL, idx: number) => <img src={imageURL} alt="" key={`image${idx}`} />)}</div>
    </>
  );
}

export default Preloader;
