import React from 'react';
import toast from 'react-hot-toast';

import styles from './ClaimTaskModal.module.scss';

import type { TypeStatus } from '@interfaces/status.types';

import useModal from '@hooks/useModal';

import { API } from '@services/API';

import { toastID, toastDuration, toastText } from '@configs/constants/toast';

import { useAppDispatch, useAppSelector } from '@store';
import { setUpgradeUser } from '@store/slices/auth';
import { setEarnResponse, setTaskCanComplete } from '@store/slices/earn';

import ModalTemplate from '@components/layouts/ModalTemplate';
import { Button, ModalTitle } from '@components/UI';

function ClaimTaskModal() {
  const dispatch = useAppDispatch();
  const settingsResponse = useAppSelector((state) => state.settings?.response);
  const currentTaskModalID = useAppSelector((state) => state.earn.currentTaskModalID);
  const task = useAppSelector((state) => state.earn.response?.tasks?.find(({ _id }) => _id === currentTaskModalID));
  const balances = useAppSelector((state) => state.auth.user?.balances);

  const [status, setStatus] = React.useState<TypeStatus>('loaded');

  const { setCloseModal } = useModal();

  const onClaim = React.useCallback(async () => {
    try {
      setStatus('loading');
      toast.loading(toastText.loading, { id: toastID, className: 'toast-loading' });

      if (task?.check_type === 'watch_ad') {
        if (settingsResponse?.ads_type === 'ADSGRAM') {
          dispatch(
            setUpgradeUser({
              balances: {
                ...balances,
                balance_tickets: balances?.balance_tickets + task?.task_reward,
              },
            })
          );

          dispatch(setTaskCanComplete({ _id: task?._id, can: false }));
        } else if (settingsResponse?.ads_type === 'ADSONAR') {
          const { data } = await API.post('/ads/adsonar');

          dispatch(
            setUpgradeUser({
              balances: {
                ...balances,
                balance_tickets: balances?.balance_tickets + data?.reward,
              },
            })
          );

          dispatch(setTaskCanComplete({ _id: task?._id, can: false }));
        }
      } else {
        const { data } = await API.post('/tasks/complate', { task_id: task?._id });

        dispatch(setEarnResponse(data.tasks));
        dispatch(setUpgradeUser(data.tasks.user));
      }

      toast.success(toastText.success, { id: toastID, duration: toastDuration, className: 'toast-success' });
      setCloseModal('claim-task');
    } catch (err) {
      console.log(err);
      const message = err?.response?.data?.error_message || toastText.error;
      toast.error(message, { id: toastID, duration: toastDuration, className: 'toast-error' });
    } finally {
      setStatus('loaded');
    }
  }, [settingsResponse, balances, task]);

  return (
    <ModalTemplate name="claim-task" type="wood-center">
      <ModalTitle className={styles.ClaimTaskModalTitle}>CLAIM REWARD</ModalTitle>

      <div className={styles.ClaimTaskModalRow}>
        <i className={styles.ClaimTaskModalTicketLogo}>
          <img src={require('@assets/img/currencies/ticket.webp')} alt="Ticket" />
        </i>

        <span className={styles.ClaimTaskModalTicketCount}>+{task?.task_reward}</span>
      </div>

      <Button className="mx-auto" onClick={onClaim} status={status} size="168px">
        Claim
      </Button>
    </ModalTemplate>
  );
}

export default ClaimTaskModal;
