import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type TypeFriend = {
  balance: number;
  position: number;
  username: string;
  is_premium: boolean;
};

type TypeFriendsResponse = {
  ref_url: string;
  refferers: TypeFriend[];
  count_refs: number;
  count_premium_refs: number;
};

interface IFriends {
  response: TypeFriendsResponse;
}

const initialState: IFriends = {
  response: null,
};

const friendsSlice = createSlice({
  name: 'friends',
  initialState,
  reducers: {
    setFriendsResponse: (state, { payload }: PayloadAction<TypeFriendsResponse>) => {
      state.response = payload;
    },
  },
});

export const { setFriendsResponse } = friendsSlice.actions;

export default friendsSlice.reducer;
