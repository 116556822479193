import React from 'react';
import { Howl } from 'howler';

import { useAppSelector } from '@store';

type TypeUseSoundReturn = {
  defaultSound: (play?: boolean, volume?: number) => void;
};

function useSound(): TypeUseSoundReturn {
  const is_sound = useAppSelector((state) => state.settings.is_sound);

  const defaultSound = React.useCallback(
    (play: boolean = false, volume: number = 0.3) => {
      const audio = new Howl({
        src: [require('@assets/sounds/click-01.mp3')],
        volume,
      });

      if (play) {
        audio.play();

        return;
      }

      if (!is_sound) return;

      audio.play();
    },
    [is_sound]
  );

  return {
    defaultSound,
  };
}

export default useSound;
