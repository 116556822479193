import React from 'react';
import classNames from 'classnames';

import './wheel.scss';

import { API } from '@services/API';

import WheelOfFortune from './utils/wheel.js';
import customTimeout from '@utils/customTimeout';

import useModal from '@hooks/useModal';
import useSound from '@hooks/useSound';

import { useAppDispatch, useAppSelector } from '@store';
import { setUpgradeUser, setDecreaseTicket } from '@store/slices/auth';
import { type TypeWheelPrize } from '@store/slices/wheel';

import Button from '@components/UI/Button';

let wheelOfFortune: any = null;

function Wheel() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const { prizes } = useAppSelector((state) => state.wheel);

  const [isWheelSpinning, setIsWheelSpinning] = React.useState(false);
  const [activeReward, setActiveReward] = React.useState(null);
  const [isInitialized, setIsInitialized] = React.useState<boolean>(false);

  const { defaultSound } = useSound();
  const { setOpenModal } = useModal();

  const wheelContent = React.useMemo(() => {
    return prizes.map(({ count, name }: TypeWheelPrize) => {
      return `
			  <div class="wheel-item">
          <span>
            <img src="${name === 'NONE' ? require('@assets/img/emoji/lose.webp') : require(`@assets/img/currencies/${name.toLowerCase()}.webp`)}" alt="${count}">
          
            <p>${name === 'NONE' ? 'LOSE' : count}</p>
          </span>
        </div>
		  `;
    });
  }, [prizes]);

  React.useEffect(() => {
    wheelOfFortune = new WheelOfFortune(document.querySelector('.wheel-of-fortune'), wheelContent);
    setIsInitialized(true);
  }, [wheelContent]);

  const onSpin = async () => {
    try {
      if (user?.balances?.balance_tickets <= 0) {
        setOpenModal('no-tickets');

        return;
      }

      if (!isInitialized || isWheelSpinning) return;

      setIsWheelSpinning(true);
      dispatch(setDecreaseTicket());

      const { data } = await API.post('/spin');
      const randomChoice = data?.sector_id;

      if (wheelOfFortune) {
        await wheelOfFortune.spin(randomChoice);
        setActiveReward(prizes[randomChoice]);

        dispatch(setUpgradeUser(data?.user));

        await customTimeout(2000);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setActiveReward(null);
      setIsWheelSpinning(false);
    }
  };

  return (
    <div className={classNames('flex flex-col items-center', isInitialized ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none')}>
      <div className="wheel-container">
        <div className="wheel">
          <div className="wheel-overflow">
            <button
              className="wheel-spin-button"
              onClick={() => {
                defaultSound();
                onSpin();
              }}
              disabled={isWheelSpinning}
            />
          </div>

          <div className="wheel-of-fortune"></div>
        </div>

        {activeReward && (
          <div className={classNames('wheel-effect', activeReward?.name === 'NONE' ? 'wheel-effect-lose' : 'wheel-effect-reward')}>
            <div className="wheel-effect-inner">
              <i>
                <img
                  src={activeReward?.name === 'NONE' ? require('@assets/img/emoji/lose.webp') : require(`@assets/img/currencies/${activeReward?.name.toLowerCase()}.webp`)}
                  alt={activeReward?.count}
                />
              </i>

              <span>{activeReward?.name === 'NONE' ? 'LOSE' : activeReward?.count}</span>
            </div>
          </div>
        )}
      </div>

      <Button className="wheel-button" onClick={onSpin} disabled={isWheelSpinning}>
        SPIN FOR
        <i className="button-ticket">
          <img src={require('@assets/img/currencies/ticket.webp')} alt="Ticket" />
        </i>
      </Button>
    </div>
  );
}

export default React.memo(Wheel);
