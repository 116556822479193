import React from 'react';

import styles from './UserControls.module.scss';

import useModal from '@hooks/useModal';
import useSound from '@hooks/useSound';

import { useAppSelector } from '@store';

const UserControls: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const settingsResponse = useAppSelector((state) => state.settings.response);

  const { defaultSound } = useSound();
  const { setOpenModal } = useModal();

  const dollarBalance = React.useMemo(() => {
    return (user?.balances?.balance_coins * settingsResponse?.lucky_to_usd_cur).toFixed(2) || 0;
  }, [user?.balances?.balance_coins, settingsResponse?.lucky_to_usd_cur]);

  const onSettings = () => {
    defaultSound();
    setOpenModal('settings');
  };

  return (
    <div className={styles.UserControls}>
      <div className="mr-[5px] last:mr-0 flex items-center overflow-hidden">
        <i className={styles.CoinLogo}>
          <img src={require('@assets/img/currencies/coin.webp')} alt="Coin" />
        </i>

        <span className={styles.Text1}>{user?.balances?.balance_coins}</span>

        <span className={styles.Text2}>${dollarBalance || 0}</span>
      </div>

      <button className={styles.SettingsButton} onClick={onSettings} type="button">
        <img src={require('@assets/img/icons/settings.webp')} alt="Settings" />
      </button>
    </div>
  );
};

export default React.memo(UserControls);
