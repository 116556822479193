import React from 'react';
import FastMarquee from 'react-fast-marquee';

import styles from './Marquee.module.scss';

import { type TypeMarqueeItem } from '@store/slices/marquee';

import { useAppSelector } from '@store';

import MarqueeItem from './MarqueeItem';

const Marquee: React.FC = () => {
  const preloaderStatus = useAppSelector((state) => state.settings.preloaderStatus);
  const marqueeItems = useAppSelector((state) => state.marquee.marqueeItems);

  return (
    <div className={styles.Marquee}>
      {marqueeItems && marqueeItems.length > 0 && (
        <FastMarquee play={preloaderStatus === 'loaded'} speed={100}>
          {marqueeItems.map((item: TypeMarqueeItem, idx: number) => (
            <MarqueeItem {...item} key={`${item.username}_${idx}`} />
          ))}
        </FastMarquee>
      )}
    </div>
  );
};

export default React.memo(Marquee);
