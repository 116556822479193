import React from 'react';

import { API } from '@services/API';

type TypeTypeUseEventValues = {
  event_data?: {
    screen?: string;
    currency?: string;
    value?: string;
    source?: string;
    type?: string;
    count?: string;
    success?: string;
    state?: string;
  };
  event_name?: string;
  Tab?: string;
};

type TypeUseEventReturn = (values: TypeTypeUseEventValues) => Promise<void>;

function useEvent(): TypeUseEventReturn {
  return React.useCallback(async (values: TypeTypeUseEventValues) => {
    try {
      const payload = {
        event_data: {
          screen: values.event_data?.screen ?? null,
          currency: values.event_data?.currency ?? null,
          value: values.event_data?.value ?? null,
          source: values.event_data?.source ?? null,
          type: values.event_data?.type ?? null,
          count: values.event_data?.count ?? null,
          success: values.event_data?.success ?? null,
          state: values.event_data?.state ?? null,
        },
        event_name: values.event_name ?? '',
        Tab: values.Tab ?? '',
      };

      const { data } = await API.post('/eventmanager', payload);

      console.log(data);
    } catch (err) {
      console.log(err);
    }
  }, []);
}

export default useEvent;
