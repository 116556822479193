// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Khq6q__Cx9dpGgOJJjwp {
  font-size: 32px;
}
@media (max-width: 430px) {
  .Khq6q__Cx9dpGgOJJjwp {
    font-size: calc(24px - -8 * (100vw - 320px) / 110);
  }
}
@media (max-width: 320px) {
  .Khq6q__Cx9dpGgOJJjwp {
    font-size: 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UI/ModalTitle/ModalTitle.module.scss","webpack://./src/styles/mixins.scss"],"names":[],"mappings":"AACA;ECEE,eAAA;ADDF;ACGE;EDJF;ICQI,kDAAA;EDHF;AACF;ACKE;EDXF;ICYI,eAAA;EDFF;AACF","sourcesContent":["@use '@styles/mixins.scss' as *;\n.ModalTitle {\r\n  @include fluid(font-size, 32px, 24px);\r\n}\r\n","@use 'sass:math';\r\n\r\n@mixin fluid($property, $max, $min, $min-screen: 320px, $max-screen: 430px) {\r\n  #{$property}: #{$max};\r\n\r\n  @media (max-width: #{$max-screen}) {\r\n    $value-range: math.div($min - $max, 1px);\r\n    $screen-range: math.div($max-screen - $min-screen, 1px);\r\n\r\n    #{$property}: calc(#{$min} - #{$value-range} * (100vw - #{$min-screen}) / #{$screen-range});\r\n  }\r\n\r\n  @media (max-width: #{$min-screen}) {\r\n    #{$property}: #{$min};\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModalTitle": `Khq6q__Cx9dpGgOJJjwp`
};
export default ___CSS_LOADER_EXPORT___;
