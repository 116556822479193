import { DOMAIN_SERVER } from './API';

export class WebSocketService {
  private socket: WebSocket | null = null;
  private reconnectAttempts = 0;
  private readonly maxReconnectAttempts = 5;
  private readonly reconnectInterval = 3000; // 3 секунды
  private endpoint: string = '';
  private onMessageCallback?: (data: any) => void;
  private onErrorCallback?: (error: any) => void;

  connect(endpoint: string = '', onMessageCallback?: (data: any) => void, onErrorCallback?: (error: any) => void): void {
    this.endpoint = endpoint;
    this.onMessageCallback = onMessageCallback;
    this.onErrorCallback = onErrorCallback;

    const fullUrl = `wss://${DOMAIN_SERVER}${endpoint}`;
    this.socket = new WebSocket(fullUrl);

    this.socket.onopen = () => {
      this.reconnectAttempts = 0;
    };

    this.socket.onmessage = (event) => {
      if (onMessageCallback) {
        try {
          const parsedData = JSON.parse(event.data);
          onMessageCallback(parsedData);
        } catch {
          onMessageCallback(event.data);
        }
      }
    };

    this.socket.onerror = (error) => {
      if (onErrorCallback) {
        onErrorCallback(error);
      }
    };

    this.socket.onclose = () => {
      this.tryReconnect();
    };
  }

  private tryReconnect(): void {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts++;
      setTimeout(() => this.connect(this.endpoint, this.onMessageCallback, this.onErrorCallback), this.reconnectInterval);
    }
  }

  send(data: any): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(data));
    }
  }

  close(): void {
    this.socket?.close();
  }
}
