import React from 'react';
import classNames from 'classnames';

import styles from './Sound.module.scss';

import useSound from '@hooks/useSound';

import { useAppDispatch, useAppSelector } from '@store';
import { setSound } from '@store/slices/settings';

import { Button } from '@components/UI';
import TemplateItem from '../TemplateItem/TemplateItem';

const Sound: React.FC = () => {
  const dispatch = useAppDispatch();
  const is_sound = useAppSelector((state) => state.settings.is_sound);

  const { defaultSound } = useSound();

  const onChange = React.useCallback(() => {
    if (!is_sound) {
      defaultSound(true);
    } else {
      defaultSound(true, 0.05);
    }

    dispatch(setSound(!is_sound));
  }, [is_sound]);

  return (
    <TemplateItem className={styles.Sound} title="SOUND">
      <Button className={styles.SoundButton} onClick={onChange} sound={false} size="168px">
        {is_sound ? 'OFF' : 'ON'}
      </Button>

      <div className={styles.SoundIcon}>
        <img src={require('@assets/img/icons/sound.webp')} alt="Sound" />

        <i className={classNames(styles.SoundNO, is_sound ? 'scale-[0.6] opacity-0' : 'scale-[1] opacity-100')}>
          <img src={require('@assets/img/icons/close.webp')} alt="No sound" />
        </i>
      </div>
    </TemplateItem>
  );
};

export default Sound;
