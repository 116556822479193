import React from 'react';
import classNames from 'classnames';

import styles from './ModalTitle.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const ModalTitle: React.FC<Props> = ({ children, className }) => {
  return <div className={classNames(styles.ModalTitle, className)}>{children}</div>;
};

export default React.memo(ModalTitle);
