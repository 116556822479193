import { useNavigate } from 'react-router-dom';

import paths from '@configs/Router/paths';

import useModal from '@hooks/useModal';

import { useAppSelector } from '@store';

import PageTemplate from '@components/layouts/PageTemplate';
import Container from '@components/layouts/Container';
import { UserControls } from '@components/UI';
import BalanceItem from './components/BalanceItem';
import Wheel from './components/Wheel';
import Marquee from './components/Marquee';
import { NoTicketsModal, WithdrawModal } from '@components/modals';

function Home() {
  const user = useAppSelector((state) => state.auth.user);

  const navigate = useNavigate();

  const { setOpenModal } = useModal();

  return (
    <>
      <PageTemplate className="page-template-top page-template-home">
        <div className="flex flex-col flex-auto justify-between">
          <Container>
            <UserControls />

            <div className="flex justify-between items-center relative z-[3]">
              <BalanceItem
                onClick={() => navigate(paths.Earn)}
                name="Ticket"
                count={user?.balances?.balance_tickets}
                leftImageURL={require('@assets/img/currencies/ticket.webp')}
                rightImageURL={require('@assets/img/icons/add.webp')}
              />

              <BalanceItem
                onClick={() => setOpenModal('withdraw')}
                name="Ton"
                count={user?.balances?.balance_ton}
                leftImageURL={require('@assets/img/currencies/ton.webp')}
                rightImageURL={require('@assets/img/icons/up.webp')}
              />
            </div>
          </Container>

          <Wheel />

          <Marquee />
        </div>
      </PageTemplate>

      {/* Modals */}

      <NoTicketsModal />

      <WithdrawModal />
    </>
  );
}

export default Home;
